import {
  type PropsWithChildren,
  type RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import clsx from 'clsx';
import { usePopper } from 'react-popper';

import { Portal } from '../portal';
import * as colors from '@src/support/colors';
import { rgba } from '@src/support/rgba';
import type { Placement } from '@popperjs/core';

type Props = {
  anchorRef?: RefObject<Element | undefined>;
  onClose?: () => void;
  open?: boolean;
  placement?: Placement;
};

export function Tooltip({
  children,
  anchorRef,
  open = false,
  placement,
}: PropsWithChildren<Props>) {
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>();
  const previousFocusRef = useRef<HTMLElement>();

  const { attributes, styles, update, state } = usePopper(
    anchorRef?.current,
    popoverElement,
    {
      placement: placement,
      modifiers: [
        {
          name: 'preventOverflow',
          options: { rootBoundary: 'document' },
        },
      ],
    }
  );

  useEffect(() => {
    if (open && popoverElement) {
      previousFocusRef.current = document.activeElement as HTMLElement;
      if (!document.documentElement.classList.contains('mouse')) {
        popoverElement.focus();
      }
    } else if (previousFocusRef.current?.focus) {
      if (!document.documentElement.classList.contains('mouse')) {
        previousFocusRef.current.focus();
      }
    }
  }, [popoverElement, open]);

  // Due to the way we layout somethings we need to constantly keep checking
  // if an element was moved (this fixes issues with the popover
  // on profile page combined with our jumpy sidebar)
  useLayoutEffect(() => {
    if (!anchorRef) {
      return;
    }

    let ref: string | undefined;

    // Less accurate than an observer but good enough
    const interval = setInterval(() => {
      const newRef = JSON.stringify(
        anchorRef.current?.getBoundingClientRect().toJSON() || {}
      );

      if (ref !== newRef) {
        ref = newRef;
        update?.();
      }
    }, 300);

    return () => clearInterval(interval);
  }, [anchorRef, update]);

  return (
    <Portal>
      <div
        ref={setPopoverElement}
        className={clsx('Tooltip', state?.placement || placement, {
          visible: open,
        })}
        tabIndex={-1}
        style={styles.popper}
        {...attributes.popper}
      >
        {children}
        <div className={clsx('arrow', {})} style={styles.arrow} />
        <style jsx>{`
          .Tooltip {
            outline: none;
            background-color: ${colors.neutralHighest};
            color: ${colors.white};
            padding: 0.5rem;
            border-radius: 0.25em;
            box-shadow: 0 0 1.25em ${rgba(colors.black, 0.16)};
            visibility: hidden;
            opacity: 0;
          }

          .Tooltip.visible {
            visibility: visible;
            opacity: 1;
          }

          .Tooltip .arrow {
            position: absolute;
            border: 0.5rem solid transparent;
          }

          .Tooltip.left .arrow,
          .Tooltip.right .arrow {
            top: 50%;
            transform: translateY(-50%);
          }

          .Tooltip.top .arrow,
          .Tooltip.bottom .arrow {
            left: 50%;
            transform: translateX(-50%);
          }

          .Tooltip.left {
            margin-right: 0.6rem;
          }
          .Tooltip.left .arrow {
            right: -1em;
            border-left: 0.5em solid ${colors.neutralHighest};
          }

          .Tooltip.right {
            margin-left: 0.6rem;
          }
          .Tooltip.right .arrow {
            left: -1em;
            border-right: 0.5em solid ${colors.neutralHighest};
          }

          .Tooltip.top {
            margin-bottom: 0.6rem;
          }
          .Tooltip.top .arrow {
            bottom: -1em;
            border-top: 0.5em solid ${colors.neutralHighest};
          }

          .Tooltip.bottom {
            margin-top: 0.6rem;
          }
          .Tooltip.bottom .arrow {
            top: -1em;
            border-bottom: 0.5em solid ${colors.neutralHighest};
          }
        `}</style>
      </div>
    </Portal>
  );
}
