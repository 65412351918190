import { Tooltip } from '@src/ui';
import { type RefObject } from 'react';
import { useIsMobile } from '@src/hooks/use-is-mobile';

export const LastUsedTooltip = (props: {
  anchorRef?: RefObject<Element | undefined>;
  provider: string;
}) => {
  const isMobile = useIsMobile();
  const lastUsed =
    typeof window !== 'undefined'
      ? window.localStorage.getItem('LAST_USED_SSO')
      : null;

  return (
    <Tooltip
      anchorRef={props.anchorRef}
      open={lastUsed === props.provider}
      placement={isMobile ? 'top' : 'right'}
    >
      Last used
    </Tooltip>
  );
};
