import type { ComponentProps } from 'react';
import { useFormikContext } from '@src/ui/formik';
import { Button } from '@src/ui';

type Props = ComponentProps<typeof Button>;

export function FormikSubmitButton({ disabled, ...props }: Props) {
  const formik = useFormikContext();

  return (
    <Button
      disabled={formik.isSubmitting || disabled}
      type="submit"
      {...props}
    />
  );
}
