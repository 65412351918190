import { Button, FontAwesomeIcon } from '@src/ui';
import { type MouseEvent, useRef } from 'react';
import { LastUsedTooltip } from '@src/components/sso-buttons/last-used-tooltip';

type Provider = 'yahoo' | 'apple' | 'google' | 'facebook';

export const SSOButton = (props: {
  provider: Provider;
  onClick: (event: MouseEvent) => void;
}) => {
  const anchorRef = useRef<Element | null>(null);
  const brandLabel: Record<Provider, string> = {
    yahoo: 'Yahoo!',
    apple: 'Apple',
    google: 'Google',
    facebook: 'Facebook',
  };

  return (
    <>
      <Button
        ref={anchorRef}
        color={props.provider}
        type="button"
        shape="rounded"
        variant={props.provider === 'google' ? 'white' : 'default'}
        onClick={props.onClick}
        fluid
      >
        <FontAwesomeIcon icon={['fab', props.provider]} fixedWidth />
        <span>Continue with {brandLabel[props.provider]}</span>
      </Button>
      <LastUsedTooltip anchorRef={anchorRef} provider={props.provider} />
    </>
  );
};
