import { useEffect } from 'react';
import Router from 'next/router';
import * as colors from '@src/support/colors';
import HeartbeatSvg from '@public/static/svgs/heartbeat.svg';
import clsx from 'clsx';

type Props = {
  message: string;
  redirectUrl?: string | any;
  page?: boolean;
};

export function RedirectOverlay({ message, redirectUrl, page = true }: Props) {
  useEffect(() => {
    if (redirectUrl) {
      const handle = setTimeout(() => Router.push(redirectUrl), 2000);

      return () => {
        if (typeof handle !== 'undefined') {
          clearTimeout(handle);
        }
      };
    }
  }, [redirectUrl]);

  return (
    <div>
      <div
        className={clsx('progress-background', {
          contained: !page,
          page: page,
        })}
      >
        <div className="main-display" />
        <strong className="page-message" data-cy="redirect-overlay-message">
          {message}
        </strong>
        <div className="heart-rate">
          <HeartbeatSvg stroke={colors.accent01High} />
        </div>
      </div>

      <style jsx>{`
        .page-message {
          font-weight: 500;
          font-size: 21px;
          color: ${colors.neutralHighest1};
          text-align: center;
        }

        .progress-background {
          align-items: center;
          background-color: ${colors.neutralLower};
          bottom: 0;
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          left: 0;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 1001;
        }

        .progress-background.page {
          position: fixed;
        }

        .progress-background.contained {
          position: absolute;
        }

        .heart-rate {
          height: 120px;
          margin: 20px auto;
          overflow: hidden;
          position: relative;
          width: 160px;
        }

        .heart-rate > :global(svg) {
          animation: heart-rate 2s linear infinite;
          stroke-dasharray: 480;
          stroke-width: 6px;
          stroke-linecap: round;
          will-change: stroke-dashoffset;
        }

        @keyframes heart-rate {
          0% {
            stroke-dashoffset: 575;
          }

          100% {
            stroke-dashoffset: 1440;
          }
        }
      `}</style>
    </div>
  );
}
